import React from 'react';
import { Container } from 'react-bootstrap';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { HelmetBlock } from '../components/HelmetBlock';
import { Header } from '../components/HeaderBlock';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/index.css';

import icoReject from '../images/icon-reject.svg';

const Reject = ({ data }) => {
    return (
        <StyledPageWrapperBlock className="step step_reject">
            <HelmetBlock data={data.wpPage} />
            <Header data={data.wpPage} />
            <StyledInfoBlock>
                <Container>
                    <div className="page-heading">
                        <img src={icoReject} alt="" />
                        <h1>Solicitud Cancelada</h1>
                    </div>
                    <p className="page-description">
                        Lamentamos comunicarte que no cumples con los requisitos para conseguir un préstamo en
                        Goprestamo. Tu solicitud de préstamo ha sido cancelada.
                    </p>
                </Container>
            </StyledInfoBlock>
        </StyledPageWrapperBlock>
    );
};

export default Reject;

const StyledPageWrapperBlock = styled.div`
    min-height: 100vh;
    background: #f2f6fc;

    header {
        background: transparent;
    }
`;

const StyledInfoBlock = styled.header`
    > .container {
        max-width: 804px;
    }

    .page-heading {
        margin: 60px 0 0;
        text-align: center;

        img {
            width: 95px;
            margin: 0 0 60px;
            @media (min-width: 768px) {
                width: 114px;
            }
        }

        h1 {
            color: #378aff;
            font-size: 16px;
            font-weight: 700;
            @media (min-width: 768px) {
                font-size: 28px;
            }
        }
    }

    .page-description {
        margin: 40px 0 0;
        color: #378aff;
        font-size: 16px;
        text-align: center;

        @media (min-width: 768px) {
            margin: 60px 0 0;
            font-size: 28px;
        }
    }
`;

export const pageQuery = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            id
            title
            databaseId
            parentDatabaseId
            seo {
                metaDesc
                title
            }
        }
    }
`;
